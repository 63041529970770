export default [
  {
    path: '/custom-views',
    name: 'custom-views',
    component: () => import('@/views/admin/CustomViews.vue'),
    meta: {
      pageTitle: 'Custom Views',
      breadcrumb: [
        {
          text: 'Custom Views',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
