import { getLightBoxOfMediaArray } from '@/common/global/functions'

export default {
  namespaced: true,
  state: {
    lightBoxData: [],
  },
  mutations: {
    SET_LIGHTBOX_DATA(state, payload) {
      state.lightBoxData = getLightBoxOfMediaArray(payload)
    },
  },
  actions: {
    closeLightBox: ({ commit }) => {
      commit('SET_LIGHTBOX_DATA', [])
    },
    lightBoxData: ({ commit }, data) => {
      if (data && data.length > 0) {
        commit('SET_LIGHTBOX_DATA', data)
      }
    },
  },
  getters: {
    getLightBoxData: state => state.lightBoxData,
  },
}
