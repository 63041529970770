import customViews from '@/router/pages/admin/customViews'
import users from '@/router/pages/admin/users'
import userTargetAds from '@/router/pages/admin/userTargetAds'

const adminRoutes = [
  ...users,
  ...customViews,
  ...userTargetAds,
]

export {
  // eslint-disable-next-line import/prefer-default-export
  adminRoutes,
}
