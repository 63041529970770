export default [
  {
    path: '/user-target-ads',
    name: 'user-target-ads',
    component: () => import('@/views/admin/UserTargetAds.vue'),
    meta: {
      pageTitle: 'User Target Ads',
      breadcrumb: [
        {
          text: 'User Target Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
