import { authGuard } from '@/auth/auth0/authGuard'

export default [
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/Callback.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'full',
      pageTitle: 'Callback',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Logout',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      publicOnly: true,
    },
  },
  // {
  //   path: '/create-account',
  //   name: 'create-account',
  //   component: () => import('@/views/auth/CreateAccount.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/phone-verification',
    name: 'phone-verification',
    component: () => import('@/views/auth/PhoneVerification.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/otp-code',
    name: 'otp-code',
    component: () => import('@/views/auth/OtpCode.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/complete-profile',
    name: 'complete-profile',
    component: () => import('@/views/auth/CompleteProfile.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/launcher',
    name: 'Launcher',
    component: () => import('@/views/Loading.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/AccountSetting.vue'),
    meta: {
      navActiveLink: 'dashboard',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/error/Error403.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
