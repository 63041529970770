export default [
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/Devices.vue'),
    meta: {
      pageTitle: 'Devices',
      breadcrumb: [
        {
          text: 'Devices',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
