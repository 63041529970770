import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
axiosIns.interceptors.request.use(async axiosConfig => {
  const user = await Vue.prototype.$auth.isAuthenticated
  if (!user) {
    return axiosConfig
  }
  const token = await Vue.prototype.$auth.getTokenSilently()
  if (
    axiosConfig.headers != null
      && axiosConfig.headers['X-Requested-With'] == null
  ) {
    // eslint-disable-next-line no-param-reassign
    axiosConfig.headers = {
      'X-Requested-With': 'XMLHttpRequest',
      userHasGroupId: store.getters['persistedStore/getSelectedGroupId'],
      Authorization: `Bearer ${token}`,
      ...axiosConfig.headers,
    }
  }
  // eslint-disable-next-line consistent-return
  return axiosConfig
})

axiosIns.interceptors.response.use(response => response,
  async error => {
    if (error.response.status === 403) {
      await Vue.swal('You are not authorized for this!')
      await store.dispatch('user/getAllUserGroupsIfNotPresent', true)
      window.location.href = ('/')
    } if (error.response.status === 401) {
      await Vue.swal('Subscription expired,You are not authorized for this!')
      await store.dispatch('user/getAllUserGroupsIfNotPresent', true)
      // await Vue.prototype.$auth.logout({ returnTo: `${window.location.origin}/error-403` })
      // await store.commit('user/CLEAR_USER_STORE')
    }
    return Promise.reject(error)
  })
export default axiosIns
