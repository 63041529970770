import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import devices from './devices'
import lightBox from '@/store/lightBox'
import packages from './packages'
import persistedStore from '@/store/persistedStore'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    app,
    user,
    lightBox,
    devices,
    appConfig,
    verticalMenu,
    packages,
    persistedStore,
  },
  plugins: [
    createPersistedState({
      paths: [
        'packages',
        'persistedStore',
      ],
      storage: window.sessionStorage,
      reducer(state) {
        return {
          packages: state.packages,
          persistedStore: state.persistedStore,
        }
      },
    }),
  ],
  strict: process.env.DEV,
})
