import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    availablePackages: {},
  },
  mutations: {
    SET_PACKAGES_AVAILABLE(state, payload) {
      state.availablePackages = payload
    },
  },
  actions: {
    async getPackagesAvailable({ commit }) {
      try {
        const { data: { data } } = await Vue.prototype.$axios.get('/user-has-group/get-all-plans')
        commit('SET_PACKAGES_AVAILABLE', data)
      } catch (e) {
        throw new Error('Error in fetching packages')
      }
    },
  },
  getters: {
    getAllPackagesAvailable: state => state.availablePackages,
  },
}
