export default [
  {
    path: '/target-ads',
    name: 'target-ads',
    component: () => import('@/views/targetAds/Index.vue'),
    meta: {
      pageTitle: 'Target Ads',
      breadcrumb: [
        {
          text: 'Target Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/target-ads/add',
    name: 'target-ad-add',
    component: () => import('@/views/targetAds/CreateTargetAd.vue'),
    meta: {
      navActiveLink: 'target-ads',
      pageTitle: 'Add Target Ad',
      breadcrumb: [
        {
          text: 'Target Ads',
          to: '/target-ads',
        },
        {
          text: 'Add Target Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/target-ads/edit-target-ad/:id',
    name: 'edit-target-ad',
    component: () => import('@/components/target-ads/EditTargetAds.vue'),
    meta: {
      navActiveLink: 'target-ads',
      pageTitle: 'Edit Target Ad',
      breadcrumb: [
        {
          text: 'Target Ads',
          to: '/target-ads',
        },
        {
          text: 'Edit Target Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
