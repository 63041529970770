export default [
  {
    path: '/media-storage',
    name: 'media-storage',
    component: () => import('@/views/MediaStorage.vue'),
    meta: {
      pageTitle: 'Media Storage',
      breadcrumb: [
        {
          text: 'Media Storage',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
