export default [
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/admin/AllGroups.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/groups/:userId',
    name: 'user',
    component: () => import('@/views/admin/user/User.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          text: 'Group',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-profile',
    name: 'user-profile',
    component: () => import('@/components/user/UserProfile.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-banner-ads',
    name: 'user-banner-ads',
    component: () => import('@/views/admin/user/UserBannerAds.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Internal Network Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-group-members',
    name: 'user-group-members',
    component: () => import('@/views/admin/user/UserGroupMembers.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Group Members',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-media-storage',
    name: 'user-media-storage',
    component: () => import('@/views/admin/user/UserMediaStorage.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Media Storage',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/users/:userId/user-devices-group',
    name: 'user-devices',
    component: () => import('@/views/admin/user/UserDevices.vue'),
    meta: {
      pageTitle: 'All Groups',
      breadcrumb: [
        {
          text: 'All Groups',
          to: '/groups',
        },
        {
          isDynamic: true,
          key: 'userId',
          text: 'Group',
          to: '/groups/:userId',
        },
        {
          text: 'Devices',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
