export default {
  namespaced: true,
  state: {
    allDevices: [],
    allGroups: [],
  },
  mutations: {
    SET_DEVICES(state, payload) {
      state.allDevices = payload
    },
    SET_GROUPS(state, payload) {
      state.allGroups = payload
    },
  },
  actions: {
    setDevice: ({ commit }, device) => {
      commit('SET_DEVICES', device)
    },
  },
  getters: {
    getDevices: state => state.allDevices,
    getGroups: state => state.allGroups,
  },
}
