export default {
  namespaced: true,
  state: {
    selectedGroupId: -1,
    groupIdFromQuery: undefined,
  },
  mutations: {
    SET_SELECTED_GROUP_ID(state, payload) {
      state.groupIdFromQuery = undefined
      state.selectedGroupId = payload
    },
    SET_GROUP_ID_FROM_QUERY(state, payload) {
      state.selectedGroupId = -1
      state.groupIdFromQuery = payload
    },
  },
  getters: {
    getSelectedGroupId(state, getters, rootState, rootGetters) {
      if (state.selectedGroupId && state.selectedGroupId !== -1) {
        return state.selectedGroupId
      }
      const allUserGroups = rootGetters['user/getAllUserGroups']
      if (allUserGroups && allUserGroups.length) {
        if (state.groupIdFromQuery) {
          const selectedGroup = allUserGroups.find(group => group.user_has_group.id === state.groupIdFromQuery)
          if (selectedGroup) {
            state.selectedGroupId = selectedGroup.user_has_group.id
            return selectedGroup.user_has_group.id
          }
        }
        state.selectedGroupId = allUserGroups[0].user_has_group.id
        return allUserGroups[0].user_has_group.id
      }
      return -1
    },
  },
}
