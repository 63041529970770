export default [
  {
    path: '/banner-ads',
    name: 'banner-ads',
    component: () => import('@/views/bannerAds/BannerAds.vue'),
    meta: {
      pageTitle: 'Internal Network Ads',
      breadcrumb: [
        {
          text: 'Internal Network Ads',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/banner-ads/add-campaigns',
    name: 'add-campaigns',
    component: () => import('@/views/bannerAds/AddCampaigns.vue'),
    meta: {
      navActiveLink: 'banner-ads',
      pageTitle: 'Add Campaign',
      breadcrumb: [
        {
          text: 'Internal Network Ads',
          to: '/banner-ads',
        },
        {
          text: 'Add Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/banner-ads/edit-campaigns/:id',
    name: 'edit-campaigns',
    component: () => import('@/components/bannerads/EditBannerCampaign.vue'),
    meta: {
      navActiveLink: 'banner-ads',
      pageTitle: 'Edit Campaign',
      breadcrumb: [
        {
          text: 'Internal Network Ads',
          to: '/banner-ads',
        },
        {
          text: 'Edit Campaign',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
